<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        青岛市放射影像质控中心2024年危急值专题讨论会
      </div>
      <section>
        <div class="div_p">
          为了进一步加强青岛市危急值管理的规范性，确保医疗安全，提升医疗服务质量，由青岛市放射影像质量控制中心主办、青岛大学附属医院医院承办的“青岛市放射影像质控中心2024年危急值专题讨论会”于2024年8月6日上午在青岛大学附属医院德英楼四楼第二会议室召开。
        </div>
        <div>
          <img src="@/assets/HY20240812/1.png" alt="">
        </div>

        <div class="div_p">
          开幕式上，青岛大学附属医院姜彦院长代表医院发表致辞，首先就各位专家的远道而来表示欢迎，其次对金征宇教授团队到来表示衷心的感谢。
        </div>
        <div>
          <img src="@/assets/HY20240812/2.png" alt="">
        </div>

        <div class="div_p">
          随后，青岛市卫健委医政医管药政副处长姜兴祥处长发表讲话，他强调了危急值管理和报告对提升医疗服务质量、确保患者安全的重要性，并指出了规范化、标准化危急值管理的必要性，以适应医疗技术发展和患者需求的增长。
        </div>
        <div>
          <img src="@/assets/HY20240812/3.png" alt="">
        </div>

        <div class="div_p">
          来宾中国医学科学院北京协和医院、国家放射影像专业质控中心主任委员金征宇教授代表团队首先对此次到访受到的欢迎表示感谢，其次发表了演讲，表明质控工作是高质量发展的基础，指出国家放射影像专业质控中心建设及医疗质量控制指标进行部署的重要性。
        </div>
        <div>
          <img src="@/assets/HY20240812/4.png" alt="">
        </div>

        <div class="div_p">
          会议期间，国家级放射影像专业质量控制中心秘书、中国医学科学院北京协和医院放射科孙昊教授，就放射影像质量控制指标内涵及建立流程进行了详尽的阐释与分析。
        </div>
        <div>
          <img src="@/assets/HY20240812/5.png" alt="">
        </div>

        <div class="div_p">
          青岛市放射影像质量控制中心主任、青岛大学附属医院放射科主任郝大鹏教授进行了青岛市放射影像危急值专家共识汇报；紧接着，与会专家们针对放射影像危急值的内容、报告时效、报告流程等方面发表自己的看法，这些内容不仅关系到医疗质量的提升，更关乎患者的生命安全和医疗服务的整体水平。
        </div>
        <div>
          <img src="@/assets/HY20240812/6.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240812/7.png" alt="">
        </div>

        <div class="div_p">
          会议最后，由青岛大学附属医院质量管理评价部孙桂霞主任进行会议总结，孙主任表示本次会议的召开对更好的推进青岛市危急值管理和报告工作至关重要，希望与会专家及医疗机构能够以此次会议为契机，进一步加深对青岛市放射影像危急值管理的认识，共同推动质控工作的深入发展。
        </div>
        <div>
          <img src="@/assets/HY20240812/8.png" alt="">
        </div>




      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "HY20240812",
  data() {
    return {

    }
  },
  created() {
    
  }
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  width: 455px;
  box-sizing: border-box;
  margin: 10px auto;
  display: block;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.h2 {
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>